import React, { ReactElement, useRef, useState } from "react";
import { DialKey, DialPadKeysProps } from "types/call/callType";
import { dialPadConfig } from "utils/constant";

const DialPadKeys: React.FC<DialPadKeysProps> = ({
  handleDigitClick,
}: DialPadKeysProps): ReactElement => {
  const holdTimeoutRef = useRef<number | null>(null);
  const [isHolding, setIsHolding] = useState(false);

  const handleZeroMouseDown = () => {
    holdTimeoutRef.current = window.setTimeout(() => {
      setIsHolding(true);
      handleDigitClick("+")(); 
    }, 400);
  };

  const handleZeroMouseUp = () => {
    if (holdTimeoutRef.current) {
      clearTimeout(holdTimeoutRef.current);
    }
    if (!isHolding) {
      handleDigitClick("0")(); 
    }
    setIsHolding(false);
  };

  return (
    <div className="flex flex-wrap justify-around">
      {dialPadConfig.map(
        (dialKey: DialKey): ReactElement => {
          if (dialKey.digit === "0") {
            return (
              <button
                className="select-none flex flex-col justify-center items-center cursor-pointer text-[1.6rem] w-[60px] h-[60px] m-[8px] bg-[rgba(211,_211,_211,_0.1)] border-[1px] border-[rgba(211,211,211,1)] rounded-[50%]"
                onMouseDown={handleZeroMouseDown}
                onMouseUp={handleZeroMouseUp}
                key={dialKey.digit}
              >
                {dialKey.digit}
                {dialKey.subset && (
                  <div className="text-[grey] text-[0.5rem] font-semibold h-3">
                    {dialKey.subset}
                  </div>
                )}
              </button>
            );
          }

          // Default behavior for other buttons
          return (
            <button
              className="select-none flex flex-col justify-center items-center cursor-pointer text-[1.6rem] w-[60px] h-[60px] m-[8px] bg-[rgba(211,_211,_211,_0.1)] border-[1px] border-[rgba(211,211,211,1)] rounded-[50%]"
              onClick={handleDigitClick(dialKey.digit)}
              key={dialKey.digit}
            >
              {dialKey.digit}
              {dialKey.subset && (
                <div className="text-[grey] text-[0.5rem] font-semibold h-3">
                  {dialKey.subset}
                </div>
              )}
            </button>
          );
        }
      )}
    </div>
  );
};

export default DialPadKeys;

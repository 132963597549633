import React, { ReactElement } from "react";

interface DialInputProps {
  callState: string;
  destinationNumber: string;
  callDuration: number;
  setDestinationNumber: (value: string) => void;
}

const DialInput: React.FC<DialInputProps> = ({
  callState,
  callDuration,
  destinationNumber,
  setDestinationNumber,
}: DialInputProps): ReactElement => {
  return (
    <div className="relative">
      {(callState === "active" || callState === "held") && (
        <div className="text-center mb-2">
          Call Duration: {Math.floor(callDuration / 60)}:
          {(callDuration % 60).toString().padStart(2, "0")}
        </div>
      )}

      <div className="flex justify-between items-center">
        <input
          type="text"
          disabled
          placeholder="+1"
          value={"+1"}
          className="border p-1 h-[56px] rounded w-[50px] text-center text-[1.6rem] text-[grey]"
        />
        <div className="justify-center text-[1.8rem] h-12 px-[0] relative inline-flex items-center">
          <input
            value={destinationNumber}
            className="border w-[220px] p-1 h-[56px] rounded pr-[20px]"
            onChange={(e) => setDestinationNumber(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default DialInput;

import { NurseLoginField } from "types/auth/auth";
import { get_creds } from "services/callService";
import { useEffect, useState } from "react";
import TelnyxDialpad from "pages/Phone/TelnyxDialpad";
import TwilioDialpad from "pages/Phone/TwilioDialpad";
import { useLocation } from "react-router-dom";
import { toast } from "utils/toast";

const NurseConnection = () => {

  const [accessToken, setAccessToken] = useState("");
  const [service, setService] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, SetPhoneNumber] = useState("");
  const location = useLocation();

  const fetchCreds = async (data: NurseLoginField) => {
    try {
      const response = await get_creds(data);
      if (!response.success) {
        toast(
          response.message || "Failed to retrieve Credentials.",
        );
        return;
      }

      setService(response.data.service);

      if (response.data.service === "telnyx") {
        const { telnyx_password, telnyx_username } = response.data;
        setUsername(telnyx_username);
        setPassword(telnyx_password);
      }
    } catch (err) {
      toast("Failed to retrieve Credentials.");
    }
  };

  useEffect(() => {
    try {
      const queryParams = new URLSearchParams(location.search);
      const encodedToken = queryParams.get("token");
      if (encodedToken) {
        const decodedData = JSON.parse(atob(encodedToken));
        setAccessToken(decodedData.token);
        SetPhoneNumber(decodedData.phoneNumber);
        sessionStorage.setItem("token", decodedData.token);
        sessionStorage.setItem("nurseId", decodedData.nurseId);
        const data = {
          nurseId: decodedData.nurseId,
          service: decodedData.service,
          encounterId: decodedData.encounterId,
        };
        fetchCreds(data);
      }
    } catch (error) {
      toast("Token is invalid");
    }
  }, []);

  return (
    <>
      {service === "telnyx" && (
        <TelnyxDialpad
          username={username}
          password={password}
          phoneNumber={phoneNumber}
        />
      )}
      {service === "twilio" && <TwilioDialpad accessToken={accessToken} />}
    </>
  );
};

export default NurseConnection;

import { useCallback, useEffect, useState } from "react";
import { Audio } from "@telnyx/react-client";
import DialTone from "assets/audio/audio.mp3";
import DialPadKeys from "pages/Phone/DialPadKeys";
import CallOptions from "pages/Phone/CallOptions";
import DialInput from "pages/Phone/DialInput";
import FadeLoader from "react-spinners/FadeLoader";
import { TelnyxRTC } from "@telnyx/webrtc";
import {
  call_control_create_conf,
  call_control_dial,
  call_transfer,
} from "services/callService";
import {
  APIResponse,
  TelnyxCall,
  TelnyxNotification,
} from "types/call/callType";
import useWebSocket from "hooks/useEcho";
import ConfirmationModal from "components/ConfirmationModal";
import { toast } from "utils/toast";
import { slice } from "lodash";

var isErrorOnCall: boolean = false;

const Dialpad = ({
  username,
  password,
  phoneNumber = "",
}: {
  username: string;
  password: string;
  phoneNumber?: string;
}) => {
  //===================== States ======================================//
  const [destinationNumber, setDestinationNumber] = useState(phoneNumber);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [isClientConnected, setIsClientConnected] = useState(false);
  const [isFirstRecipient, setIsFirstRecipient] = useState(true);
  const [addCall, setAddCall] = useState(true);
  const [transferCall, setTransferCall] = useState(true);
  const [mute, setMute] = useState(false);
  const [dialKeys, setDialKeys] = useState(true);
  const [timer, setTimer] = useState<number | NodeJS.Timeout | null>(null);
  const [callDuration, setCallDuration] = useState(0);
  const [isCallAnswered, setIsCallAnswered] = useState(false); //socket

  const [isFirstCall, setIsFirstCall] = useState(true);
  const [isTransferred, setIsTransferred] = useState(false);
  const [isConference, setIsConference] = useState(false);

  const [CCID1, setCCID1] = useState("");
  const [CCID2, setCCID2] = useState("");

  const [call, setCall] = useState<TelnyxCall | null>(null);
  const [callState, setCallState] = useState("");

  const [isModal, setIsModal] = useState(false);

  const handleEvent = () => {
    setIsCallAnswered(true);
  };

  useWebSocket(
    `call-answered${process.env.REACT_APP_REFERENCE_ID}`,
    "CallAnsweredEvent",
    handleEvent
  );
  //===================== variables ======================================//

  useEffect(() => {
    if (username && password) {
      const telnyxClient = new TelnyxRTC({
        login: username,
        password: password,
      });

      telnyxClient.connect();

      telnyxClient
        .on("telnyx.ready", () => {
          setIsClientConnected(true);
        })
        .on("telnyx.notification", (notification: TelnyxNotification) => {
          if (notification.type === "callUpdate") {
            const updatedCall: TelnyxCall = notification.call;

            if (updatedCall.state === "ringing") {
              updatedCall.answer();
            }
            if (!isErrorOnCall) {
              setCall(updatedCall);
              setCallState(updatedCall.state);
            }

            if (updatedCall.state === "hangup") {
              setCall(null);
              setCallState("idle");
            }
          }
        });
    }
  }, [username, password]);

  useEffect(() => {
    const cleanup = () => {
      if (timer) {
        clearInterval(timer);
      }
    };

    if (callState === "active" && isCallAnswered) {
      const callTimer = setInterval(
        () => setCallDuration((prev: number): number => prev + 1),
        1000
      );
      setTimer(callTimer);
      setDialKeys(false);
    }

    if (callState === "destroy") {
      setDialKeys(true);
    }

    return () => cleanup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callState, isCallAnswered]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (
        (e.key === "r" && e.ctrlKey && !e.shiftKey) || // Ctrl + R
        (e.key === "r" && e.ctrlKey && e.shiftKey) || // Ctrl + Shift + R
        e.key === "F5" // F5
      ) {
        e.preventDefault(); // Prevent the default reload behavior
        if (
          callState === "active" ||
          callState === "held" ||
          callState === "ringing"
        ) {
          setIsModal(true);
        } else {
          window.location.reload();
        }
      }
    };

    // Add the event listener
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [callState]);
  //====================== Functions ==============================//

  const isValidPhoneNumber = (phoneNumber: string) => {
    const cleanedNumber = phoneNumber.replace(/\s/g, "");
    const regex = /^\d{10}$/;
    if (regex.test(cleanedNumber)) {
      const formattedNumber: string = "+1" + cleanedNumber;
      return { isValid: true, formattedNumber };
    }
    return { isValid: false, cleanedNumber };
  };

  const firstCall = async (webRTCClient: string, to: string) => {
    try {
      if (!to) {
        toast("Please enter a destination number.");
        return;
      }

      const { isValid, formattedNumber } = isValidPhoneNumber(to);

      if (!isValid) {
        toast("Please enter a valid destination number.");
        return;
      }

      isErrorOnCall = false;
      setIsFirstCall(false);

      // Dial the first call
      const webRTCCall: APIResponse = await call_control_dial(
        webRTCClient,
        formattedNumber as string // make a call through formatted number
      );

      if (!webRTCCall.success) {
        toast(webRTCCall.message || "Failed to make the call.");
        setIsFirstCall(true);
        setCall(null);
        setCallState("");
        isErrorOnCall = true;
        return;
      }

      window.opener.postMessage({ action: "callStarted" }, "*");

      // Extract and set call control IDs (CCID)
      const { CCID1, CCID2 } = webRTCCall.data;
      setCCID1(CCID1);
      setCCID2(CCID2);
    } catch (err) {
      setIsFirstCall(true);
      toast("Error in making the call.");
    }
  };

  const handleDigitClick = useCallback(
    (value: string) => () => {
      const audio = new window.Audio(DialTone);
      audio.play();

      setDestinationNumber((destinationNumber: string): string =>
        destinationNumber.concat(value)
      );
    },
    []
  );

  const handleDialClick = () => {
    const webClient = `sip:${username}@sip.telnyx.com`;
    firstCall(webClient, destinationNumber);
  };

  const setToDefaultState = async () => {
    setDestinationNumber("");
    setShowMoreOptions(false);
    setIsFirstRecipient(true);
    setAddCall(true);
    setTransferCall(true);
    setMute(false);
    setDialKeys(true);
    setIsTransferred(false);
    setIsConference(false);
    setCCID1("");
    setCCID2("");
    setIsFirstCall(true);
    setIsCallAnswered(false);
    setCallDuration(0);
    window.opener.postMessage({ action: "callEnded" }, "*");
  };

  const handleHangUpClick = () => {
    call?.hangup();
    setToDefaultState();
  };

  const handleHoldClick = async () => {
    try {
      await call?.toggleHold();
    } catch (error) {
      toast("Error holding the call.");
    }
  };

  const handleMuteClick = async () => {
    call?.toggleAudioMute();
    setMute((prev: boolean): boolean => !prev);
  };

  const handleDialKeysToggle = async () => {
    setDialKeys((prev: boolean): boolean => !prev);
  };

  const handleShowMoreOptionsToggle = async () => {
    setShowMoreOptions((prev: boolean): boolean => !prev);
  };

  const handleBackspaceClick = () => {
    // Explicitly use the browser's Audio constructor via the window object
    const audio = new window.Audio(DialTone);
    audio.play();

    setDestinationNumber((destinationNumber: string): string =>
      destinationNumber.slice(0, -1)
    );
  };

  const handleAddCall = () => {
    setDialKeys(true);
    setDestinationNumber("");
    setAddCall(false);
  };

  const handleClickTransfer = () => {
    setDialKeys(true);
    setDestinationNumber("");
    setTransferCall(false);
  };

  const handleAddParticipantClick = async () => {
    try {
      // Validate destination number input
      if (!destinationNumber) {
        toast("Please enter a destination number.");
        return;
      }

      const { isValid, formattedNumber } =
        isValidPhoneNumber(destinationNumber);

      if (!isValid) {
        toast("Please enter a valid destination number.");
        return;
      }

      // Create conference call
      setIsConference(true);
      const createConference: APIResponse = await call_control_create_conf(
        CCID1,
        CCID2,
        formattedNumber as string
      );

      if (!createConference.success) {
        toast(createConference.message || "Failed to create conference.");
        setIsConference(false);
        return;
      }

      // Update state after successful conference creation
      setDialKeys(false);
      setShowMoreOptions(false);
      setIsFirstRecipient(false);
    } catch (error) {
      toast("Error adding participant.");
    }
  };

  const handleCallTransfer = async () => {
    try {
      // Validate destination number input
      if (!destinationNumber) {
        toast("Please enter a destination number.");
        return;
      }

      const { isValid, formattedNumber } =
        isValidPhoneNumber(destinationNumber);

      if (!isValid) {
        toast("Please enter a valid destination number.");
        return;
      }

      // Set transfer state before making the API call
      setIsTransferred(true);

      // Initiate call transfer
      const transferCall: APIResponse = await call_transfer(
        CCID2,
        formattedNumber as string
      );

      if (!transferCall.success) {
        toast(transferCall.message || "Error transferring the call.");
        setIsTransferred(false); // Reset state if the transfer fails
        return;
      }

      // Reset the state upon successful transfer
      setToDefaultState();
    } catch (err) {
      toast("Error In Transfering Call");
    }
  };

  // ========================= component flow ==============================//

  if (!isClientConnected)
    return <FadeLoader color="#3498db" className="mx-auto" />;

  return (
    <div
      className={`flex flex-col justify-between max-w-[280px] mx-auto gap-5 ${
        !dialKeys ? "h-full" : ""
      }  `}
    >
      <DialInput
        callState={callState}
        callDuration={callDuration}
        destinationNumber={destinationNumber}
        setDestinationNumber={setDestinationNumber}
      />
      {/* Dial Keys */}
      {dialKeys && <DialPadKeys handleDigitClick={handleDigitClick} />}
      {/* call Options */}

      <CallOptions
        callState={callState}
        addCall={addCall}
        transferCall={transferCall}
        showMoreOptions={showMoreOptions}
        dialKeys={dialKeys}
        isClientConnected={isClientConnected}
        mute={mute}
        isTransferred={isTransferred}
        isConference={isConference}
        isFirstRecipient={isFirstRecipient}
        isFirstCall={isFirstCall}
        handleAddCall={handleAddCall}
        handleDialKeysToggle={handleDialKeysToggle}
        handleAddParticipantClick={handleAddParticipantClick}
        handleClickTransfer={handleClickTransfer}
        handleMuteClick={handleMuteClick}
        handleCallTransfer={handleCallTransfer}
        handleHoldClick={handleHoldClick}
        handleHangUpClick={handleHangUpClick}
        handleShowMoreOptionsToggle={handleShowMoreOptionsToggle}
        handleDialClick={handleDialClick}
        handleBackspaceClick={handleBackspaceClick}
      />
      <Audio stream={call?.remoteStream} />

      {isModal && (
        <ConfirmationModal
          label=""
          message="Are you sure you want to reload the page? Your call will be disconnected."
          onConfirm={() => window.location.reload()}
          onCancel={() => setIsModal(false)}
          setIsModal={setIsModal}
        />
      )}
    </div>
  );
};

export default Dialpad;
